'use client'
import { Provider } from "react-redux"
import { ReactNode, useEffect } from "react"
import { NAMESPACE, store } from "@/redux/store"

export const ReduxProvider = ({children}: {
  children: ReactNode
}) => {
  useEffect(() => {
    store.subscribe(()=>{
      localStorage.setItem(NAMESPACE, JSON.stringify(store.getState()))
    })
  }, [])
  return (
    <Provider store={store}>
      {children}
    </Provider>
  )
}