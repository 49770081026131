'use client'

import { SearchResults, searchType } from "@/components/app/search/SearchResults";
import { useSearchUi } from "@/contexts/SearchUiProvider";
import { CheckIcon, Cog6ToothIcon, XMarkIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { usePathname } from "next/navigation";
import React, { useEffect, useRef, useState } from "react"
import { Menu } from '@headlessui/react'
import { DrupalNodeArticle, DrupalNodeBasicPage, DrupalTaxonomyTermProduct } from "@/types";
import { tryGetDrupalPageFromPath } from "@/lib/drupal/drupal.server";
import { SearchProductFilterToggle } from "./SearchProductFilterToggle.client";
import AiSvg from "@/assets/ai.svg"

export const SidebarSearch = ({accessConfiguration = false}: {
  accessConfiguration: boolean
}) => {
  const pathname = usePathname()
  const { isVisible, hideSearchUi, runSearch, clearSearch, current } = useSearchUi();
  const [products, setProducts] = useState<DrupalTaxonomyTermProduct[]>([])
  const [helpEngine, setHelpEngine] = useState<searchType>('search')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
      tryGetDrupalPageFromPath<DrupalNodeBasicPage|DrupalNodeArticle>(pathname).then((res) => {
        if (!res?.resource) {
          setProducts([])
        }
        const resource = res?.resource
        if (resource?.field_product) {
          setProducts([resource.field_product])
        }
        else if (resource?.field_products) {
          setProducts(resource.field_products)
        }
      })
  }, [pathname])

  useEffect(() => {
    const searchUiEventHandler = (e:CustomEvent<SearchUiEventProps>) => {
      if (e.detail.show) {
        inputRef.current?.focus()
        if (e.detail.search) {
          if (inputRef.current) {
            inputRef.current.value = e.detail.search.searchPhrase
          }
        }
      } 
      else {
        inputRef.current?.blur()
      }
    }

    document.addEventListener('searchui', searchUiEventHandler)

    return () => {
      document.removeEventListener('searchui', searchUiEventHandler)
    }
  })

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
        // Toggle draw on Ctrl + "/"
        if (e.key == "/" && e.ctrlKey) {
          !isVisible ? runSearch() : hideSearchUi()
        }
    }
    document.addEventListener("keyup", onKeyUp);
    return () => {
        document.removeEventListener("keyup", onKeyUp);
    }
  })

  const productNames = (() => {
    if (products.length) {
      return (current === undefined || current?.products !== undefined) ? products.map(p => p.name) : undefined
    }
    if (current?.products) {
      return current?.products
    }
  })()

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      const query = (e.target as HTMLInputElement).value ?? ''
      if (query.length > 0) {
        runSearch(
          query,
          // Set product filters if they are present in current search or 
          // there is no search yet and product filters are available.
          productNames,
        )
      }
    }
  }

  function checkForSearchClear(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value == "") {
      clearSearch()
    }
  }

  const searchOptions: {
    name: searchType
    title: string
  }[] = [
    {name: 'search', title: 'Search'},
    {name: 'aisearch', title: 'Pre-trained AI'}
  ]

  return (
    <div className={classNames("absolute bg-white min-h-screen xl:static lg:right-0", isVisible ? "shadow-2xl" : "")}>  
        <div className={classNames(isVisible ? "w-screen lg:w-[33dvw]" : "w-0", "transition-all ease-in-out duration-300 overflow-hidden")}>
          <div className="py-6 px-8 relative max-h-screen xl:fixed overflow-y-auto lg:w-[33dvw]">
          <div className="flex flex-row mb-1">
            <p aria-label="subtext" className="text-lg/[1.3] text-gray-800 font-display font-bold flex-grow">
              {productNames && productNames.length > 0 ? productNames[0] : ''} Help
            </p>
            {accessConfiguration && (
              <>
                <div>
                  <Menu as="div" className="relative inline-block text-left z-30">
                    <Menu.Button className='rounded p-2 hover:bg-navy-600/10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75'><Cog6ToothIcon className="w-[24px]" /></Menu.Button>
                    <Menu.Items 
                      className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-navy-300 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                    >
                      {searchOptions.map(opt => (
                        <Menu.Item key={opt.name}>
                          {({ active }) => (
                            <button onClick={() => setHelpEngine(opt.name)} className={classNames(active ? 'bg-navy-600 text-white' : '', "group flex w-full items-center px-2 py-2 text-sm")}>
                            {helpEngine == opt.name ? (
                              <CheckIcon className="w-4 pr-1" />
                            ):(
                              <span className="w-4 pr-1"></span>
                            )}
                              {opt.title}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Menu>
                </div>
              </>
            )}
            <div className="self-start"><button aria-label="close" onClick={hideSearchUi} className="rounded p-2 hover:bg-navy-600/10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"><XMarkIcon className="w-[24px]" /></button></div>
          </div>
          <div className="flex items-end mb-2">
            <h2 className="text-4xl/[1.3] text-navy-600 font-display font-bold mb-0 mt-1 flex-grow flex">
            Ask a question
            {helpEngine == "aisearch" && <AiSvg className="w-8" />}
            </h2>
            {helpEngine == 'search' && (
              <SearchProductFilterToggle products={products} />
            )}
          </div>

          <div className="mb-4">
            <input 
              onKeyDown={onInputKeyDown}
              onChange={checkForSearchClear}
              ref={inputRef}
              autoComplete='off'
              placeholder="Provide a question. Tell us what you're looking for..."
              className="rounded border border-gray-600 p-2 w-full placeholder:text-gray-700"
              />
          </div>
            <SearchResults 
              searchFilters={{
                products: current?.products
              }} 
              searchString={current?.searchPhrase} 
              searchType={helpEngine}
              products={products}
               />
          </div>
        </div>
    </div>
  )
}
