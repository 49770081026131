'use client'
import { sendGTMEvent } from '@next/third-parties/google';
import React, { createContext, useContext, useState, ReactNode } from 'react';

type SearchUiContextType = {
  isVisible: boolean;
  runSearch: (query?: string, products?: string[], referer?: string) => void;
  hideSearchUi: () => void;
  clearSearch: () => void;
  current?: Search
};

const SearchUiContext = createContext<SearchUiContextType>(null!);

export const useSearchUi = () => useContext(SearchUiContext);

type SearchUiProviderProps = {
  children: ReactNode;
};

export type SearchUiEventProps = {
  show: boolean
  search?: Search
}

export const SearchUiProvider: React.FC<SearchUiProviderProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentSearch, setCurrentSearch] = useState<Search>()


  const runSearch = (query?: string, products?: string[], referer?: string) => {
    const search = query ? currentSearch?.augment(query, products, referer) ?? new Search(query, products, referer) : undefined

    const event = new CustomEvent<SearchUiEventProps>('searchui', { detail: {
      show: true, 
      search
    }});
    document.dispatchEvent(event);

    if (!isVisible) {
      sendGTMEvent({
        event: 'searchSidebar',
        value: 'show'
      })
      setIsVisible(true);
    }
    setCurrentSearch(search)
  }

  const clearSearch = () => {
    setCurrentSearch(undefined)
  }

  const hideSearchUi = () => {
    const event = new CustomEvent<SearchUiEventProps>('searchui', { detail: {show: false} });
    document.dispatchEvent(event);
    
    if (isVisible)  {
      sendGTMEvent({
        event: 'searchSidebar',
        value: 'hide'
      })
      setIsVisible(false);
    }
  }

  return (
    <SearchUiContext.Provider value={{ isVisible, runSearch, hideSearchUi, clearSearch, current: currentSearch }}>
      {children}
    </SearchUiContext.Provider>
  );
};

export interface SearchInterface {
  searchPhrase: string;
  products?: string[];
  referer?: string;
  previous?: Search;
}

class Search implements SearchInterface {
  searchPhrase: string;
  products?: string[];
  referer?: string;
  previous?: Search;

  /**
   * @param searchPhrase
   * @param products
   */
  constructor(searchPhrase: string, products?: string[], referer?: string, previous?: Search) {
    this.searchPhrase = searchPhrase;
    this.products = products
    this.referer = referer
    this.previous = previous
  }

  augment(searchPhrase: string, products?: string[], referer?: string): Search {
    return new Search(searchPhrase, products, referer, this)
  }
}