'use client'

import { useUserInformation } from "@/contexts/UserProvider"
import { EyeIcon } from "@heroicons/react/24/outline"
import { sendGTMEvent } from "@next/third-parties/google"

function trackSupportCTA(query?: string) {
  sendGTMEvent({
    event: 'supportFromSearch',
    value: query
  })
}
export const SupportPrompt = ({query}: {
  query?: string
}) => {

  const { isAcquian } = useUserInformation()

  if (isAcquian && query) {
    return <div className="rounded-lg p-5 pb-2 bg-gray-400">
      <div className="flex gap-1 text-blue-600 text-sm mb-2"><EyeIcon className="w-4" /> Acquian only</div>
      <h3 className="text-blue-600 text-xl font-medium">{"Can't find what you're looking for?"}</h3>
      <p className="mb-2">If you think you&apos;ve found a gap in our content, let us know and make a request to our content team.</p>
      <p>This will take you to Jira to create the request.</p>
      <p className="my-2 py-4">
        <a className="transition-colors duration-300 p-4 hover:bg-blue-800 text-white bg-blue-600 rounded after:bg-white" href="https://acquia.atlassian.net/secure/CreateIssue.jspa?issuetype=3&pid=10110" target="_blank">Make a content request</a>
      </p>
    </div>
  }

  return <div className="bg-yellow-300 rounded-lg p-6 my-4">
  <p>{"If you can't find what you're looking for, then you can "}
    <a className="text-blue-600 underline" onClick={() => trackSupportCTA(query)} href="https://support-acquia.force.com/s/contactsupport" target="_blank">reach out to our Support team</a> for further assistance.
  </p>
</div>
}