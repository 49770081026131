'use client'

import { useSearchUi } from "@/contexts/SearchUiProvider"
import Loading from '@/assets/loading.svg'
import { SupportPrompt } from "./SupportPrompt.client"
import { DrupalTaxonomyTermProduct } from "@/types"
import { Fragment } from "react"

export const SearchHelp = ({query, hasResults = false, isWorking, products}: {
  query?: string
  hasResults: boolean
  isWorking: boolean
  products?: DrupalTaxonomyTermProduct[]
}) => {

  const { runSearch } = useSearchUi()

  if (isWorking) {
    return <div className="p-2 flex flex-col gap-y-2 place-items-center">
      <div className='animate-pulse py-10'>
        <Loading />
      </div>
    </div>
  }

  if (query && hasResults) {
    return <SupportPrompt query={query} />
  }
  
  if (query === undefined) {
    const common_questions = products?.filter(product => product.field_common_search_questions?.length);
    return <>
    <div className="bg-teal-300 rounded-lg p-6 my-4">
      {common_questions?.map(product => <Fragment key={product.id}>
        <h3>{product.name} common questions</h3>
        
        <ul className="list-disc flex flex-col space-y-2">
        {product.field_common_search_questions?.map((question, i) => 
          <li key={i} className="ml-8"><button className="underline text-blue-600 text-left" onClick={(e) => runSearch(question, [product.name], 'question')}>{question}</button></li>
        )}
        </ul>
      </Fragment>)}
      {(common_questions === undefined || common_questions.length < 1) && (<>
        <h3>Common questions</h3>
        <ul className="list-disc flex flex-col space-y-2">
          <li className="ml-8"><button className="underline text-blue-600" onClick={(e) => runSearch("How do I upload an SSL certificate?")}>How do I upload an SSL certificate?</button></li>
          <li className="ml-8"><button className="underline text-blue-600" onClick={(e) => runSearch("How do I get PHP error logs?")}>How do I get PHP error logs?</button></li>
          <li className="ml-8"><button className="underline text-blue-600" onClick={(e) => runSearch("Can I run a task on a schedule?")}>Can I run a task on a schedule?</button></li>
          <li className="ml-8"><button className="underline text-blue-600" onClick={(e) => runSearch("What security features are available?")}>What security features are available?</button></li>
        </ul>
      </>)}
    </div>
    <SupportPrompt  query={query} />
    </>
  }
}