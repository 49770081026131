import { DrupalTaxonomyTermProduct } from "@/types"
import { SearchFilters } from "../../../lib/search/server-actions.server"
import { OpenAISearchResults } from "./OpenAISearchResults.client"
import { SUResults } from "./SUResults.client"
import { SearchHelp } from "./SearchHelp.client"

export type searchType = 'search' | 'RAG' | 'aisearch'

export const SearchResults = ({searchString = undefined, searchFilters = undefined, searchType = 'RAG', products}: {
  searchString?: string
  searchFilters?: SearchFilters
  searchType?: searchType
  products?: DrupalTaxonomyTermProduct[]
}) => {
  searchString = searchString ? searchString.trim() : undefined

  if (searchString && searchType === 'aisearch') {
    // searchString += searchFilters?.pathname ? ` path:${searchFilters?.pathname}` : ''
    searchString += searchFilters?.products ? ` category:${searchFilters?.products?.join('|')}` : ''
  }

  return <>
    {searchType == 'search' && (
      <>
        <SUResults query={searchString} filters={searchFilters}>
          {({isWorking, searchResults}) => (
            <SearchHelp products={products} isWorking={isWorking} query={searchString} hasResults={searchResults !== undefined && searchResults.data.result.total > 0} />
          )}
        </SUResults>
      </>
    )}

    {(searchType == 'RAG' || searchType == 'aisearch') && (
      <>
        <OpenAISearchResults query={searchString} filters={searchFilters} type={searchType}>
          {({isWorking, response}) => (
            <SearchHelp products={products} isWorking={isWorking} query={searchString} hasResults={response.length > 0} />
          )}
        </OpenAISearchResults>
      </>
    )}
  </>
}