'use client'

import { SearchFilters } from "../../../lib/search/server-actions.server"
import React, { ReactNode, useEffect, useState } from "react"
import { GenerativeResponse, getGenerativeResponse } from "../../../lib/search/openai-server-actions.server";
import Image from "next/image";
import { Body } from "@/components/wysiwyg/Body";
import { parse } from 'marked';
import { searchType } from "./SearchResults";
import { Info } from "@/components/widget/info";
import { Annotation } from "openai/resources/beta/threads/messages.mjs";
import Link from "next/link";

export const OpenAISearchResults = ({query, filters, children, type}: {
  query?: string
  filters?: SearchFilters
  type: searchType
  children: React.FC<{
    isWorking: boolean
    response: ReactNode[]
  }>
}) => {

    const [response, setResponse] = useState<ReactNode[]>([])
    const [references, setReferences] = useState<GenerativeResponse['references']>()
    const [isWorking, setIsWorking] = useState(false)
    const [aiQuery, setAiQuery] = useState<string>()
    const [searchType, setSearchType] = useState<searchType>()

    useEffect(() => {
        if (!aiQuery || !searchType) {
            return
        }

        setIsWorking(true)

        getGenerativeResponse(aiQuery, searchType).then((response) => {
          setReferences(response.references)
          const components = response.content.map((content, index):ReactNode => {
            switch (content.type) {
              case 'text':
                const textValue = replaceCitations(content.text.value, content.text.annotations, response.references)
                return <Body value={parse(textValue, {async: false}) as string} key={`text-result-${index}`} />
              case 'image_url':
                return <Image src={content.image_url.url} alt={content.image_url.detail ?? 'image'} key={`image-result-${index}`} />
              default:
                console.warn(`Missing content type support for ${content.type}`, content)
                return <></>
            }
          })
          setResponse(components)
          setIsWorking(false)
        })

        // Prevent further searches to protect the API from search stampedes.
    }, [aiQuery, searchType])

    if (aiQuery !== query) {
      setAiQuery(query)
    }
    if (searchType !== type) {
      setSearchType(type)
    }

    return <>
      {(isWorking || response.length > 0) && (
        <div className="px-4 bg-yellow-300 rounded-lg text-sm flex">
          <div className="pt-3 pr-2"><Info>Using OpenAI GPT-4o</Info></div>
          <p>This uses AI generated content optimised with content from docs.acquia.com.</p>
        </div>
      )}
      {!isWorking && query !== undefined && response.length > 0 && (
        <>
          {response}
          {references && Object.keys(references).length > 0 && (
            <>
            <h4 className="font-medium mt-8">References</h4>
            <ol className="ml-4 pl-4 list-decimal mb-8">
              {Object.keys(references).filter(k => references[k].path).map(k => (
                <li key={k} className="my-1">
                  <Link className="text-blue-600 underline" href={references[k].path[0].alias}>{references[k].label}</Link>
                </li>
              ))}
            </ol>
            </>
          )}
        </>
      )}
      {children({isWorking, response})}
    </>
}

function replaceCitations(content:string, annotations: Annotation[], references: GenerativeResponse['references']) {
  return annotations.reduce((p, c) => {
    if (c.type != 'file_citation') {
      return p
    }
    const i = Object.keys(references).indexOf(c.file_citation.file_id) + 1
    const reference = references[c.file_citation.file_id] ?? undefined
    if (!reference) {
      return p.replace(c.text, '')
    }
    return p.replace(c.text, `<sup><a class="underline mx-0.5" href="${reference.path[0].alias}" title="${reference.label}">${i}</a></sup>`)
  }, content)
}