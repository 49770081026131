'use client'
import React, { useState } from 'react';

import LogoSVG from '@/public/assets/logo.svg';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { DrupalMenuLinkContent } from 'next-drupal';
import Link from 'next/link';
import { Disclosure, Menu } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useSearchUi } from '@/contexts/SearchUiProvider';
import classNames from 'classnames';
import { Session } from 'next-auth';
import { UserNav } from './UserNav.client';
import { HideOnIFrameCompact } from '@/contexts/IFrameCompactProvider';

export const Header = ({ menu, session }: {
  menu: DrupalMenuLinkContent[],
  session: Session | null
}) => {
  const [open, setOpen] = useState(false);

  const { isVisible, runSearch, hideSearchUi } = useSearchUi()
  const toggleSearch = () => {
    isVisible ? hideSearchUi() : runSearch()
  }
  
  return (
    <header>
      <div className='bg-white'>
        <Disclosure as="nav" className="p-3 text-navy-600">
            <>
              <div className="mx-auto container px-2">
                <div className="relative flex h-16 items-center justify-between">
                  <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                    <div className="flex flex-shrink-0 items-center">
                      <Link className="flex place-items-baseline" href="/">
                        <LogoSVG className='me-1' />
                        <span className='uppercase text-[31px] font-thin'>Docs</span>
                      </Link>
                    </div>
                    <div className="hidden sm:ml-6 lg:block">
                      <div className="flex space-x-4">
                        {menu && menu.map((link, i) => {
                          return link.items ?
                            (<DropDown buttonLink={link.url} route={link.route} buttonLabel={link.title} key={`header-menu-item-${i}`} items={link.items ?? []} />)
                            : (<Link key={`header-menu-item-${i}`} className="flex hover:bg-gray-400 p-3 rounded-lg px-3 py-2 text-sm font-medium" href={link.url}>{link.title}</Link>)
                        })}
                      </div>
                    </div>
                  </div>
                  <div className='hidden md:flex basis-1/3 gap-x-4'>
                    <a className='border w-fit whitespace-nowrap border-navy-600 rounded p-3 hover:bg-navy-600 hover:text-white transition' href="https://www.acquia.com/" target="_blank">Acquia.com</a>
                    <button className={classNames('border w-fit whitespace-nowrap border-white rounded-full p-3 hover:bg-navy-600 hover:text-white transition-all duration-300', isVisible ? 'text-white bg-navy-600' : '')} onClick={toggleSearch}><MagnifyingGlassIcon className="w-[24px]" /></button>
                    <div className='flex-grow flex max-h-[50px]'><div className='flex-grow'/><UserNav session={session} /></div>
                  </div>
                  <div className="inset-y-0 right-0 flex items-center lg:hidden print:hidden ml-4">
                    {/* Mobile menu button*/}
                    <HideOnIFrameCompact>
                      <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2  hover:bg-navy-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" onClick={() => setOpen(!open)}>
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true"  />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </HideOnIFrameCompact>
                  </div>
                </div>
                <Disclosure.Panel className="lg:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 divide-y divide-solid divide-navy-300">
                  <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start p-2 md:hidden'>
                    <a className='border w-fit whitespace-nowrap border-navy-600 rounded p-3 hover:bg-navy-600 hover:text-white transition' href="https://www.acquia.com/" target="_blank">Acquia.com</a>
                  </div>
                  {menu && menu.map((link, i) => {
                    return link.items ?
                      (
                        <div className='block' key={`header-mobile-menu-item-${i}`}>
                          <DropDown buttonLink={link.url} route={link.route} buttonLabel={link.title} key={`header-mobile-menu-item-${i}`} items={link.items ?? []} />
                        </div>
                      )
                      : (
                        <Disclosure.Button
                          key={link.title}
                          as="a"
                          href={link.url}
                          className="block rounded-md px-3 py-2 text-base font-medium"
                        >
                          {link.title}
                        </Disclosure.Button>
                      )
                  })}
                </div>
              </Disclosure.Panel>
              </div>
            </>
        </Disclosure>
      </div>
    </header>
  )
};

interface DropDownProps {
  buttonLabel: string,
  buttonLink: string | null,
  items: DrupalMenuLinkContent[],
  route: {
    name: string
  }
}

const DropDown = (props: DropDownProps) => {
  return (
    <div className='lg:flex relative'>
      <Menu>
        <Menu.Button className="flex hover:bg-gray-400 p-3 rounded-lg">
          {props.buttonLink && props.route.name != '' ? (
            <>
              <Link href={props.buttonLink}>{props.buttonLabel}</Link>
              <ChevronDownIcon className='ml-2 mt-1 w-4 overflow-visible' />
            </>
          ) : (
            <>{props.buttonLabel} <ChevronDownIcon className='ml-2 mt-1 w-4 overflow-visible' /></>
          )}
        </Menu.Button>
        <Menu.Items className="flex flex-col flex-grow lg:absolute z-10 lg:mt-12 lg:w-56 lg:rounded-md border-gray-600 border-1 bg-white lg:shadow-lg ">
          {props.items.map((link) => {
            const is_external_link: boolean = link.url.indexOf("http") === 0;
            return (
              <Menu.Item key={link.id}>
                {({ active }) => (
                  <Link className={`${active && 'bg-gray-400'} p-3 py-2 text-black`} href={link.url} target={`${is_external_link ? '_blank' : ''}`}>{link.title}</Link>
                )}
              </Menu.Item>
            )
          })}
        </Menu.Items>
      </Menu>
    </div>
  )
}

const MenuLinks = ({ menu, className}: {
  menu: DrupalMenuLinkContent[],
  className: string
}) => {
  return (
    <>
      {menu?.filter((link) => link.enabled).map((link) => {
        return (
          <li key={link.id} className={className}>
            {link.url == '' ? link.title : (
              <Link href={link.url}>{link.title}</Link>
            )}
            {link.items?.length ? (
              <>
                <ChevronDownIcon className='ml-2 w-4 overflow-visible' />
                <ul className='absolute bg-white z-10 rounded shadow mt-10 hidden hover:block'>
                  <MenuLinks menu={link.items} className='px-3 my-2 hover:bg-gray-400' />
                </ul>
              </>
            ) : null}
          </li>
        );
      })}
    </>
  );
}
