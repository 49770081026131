'use client'

import { configureStore } from '@reduxjs/toolkit'
import appReducer, { Actions, AppState } from './reducer'

export const NAMESPACE = 'appState'

const storedState = typeof window !== "undefined" ? window.localStorage.getItem(NAMESPACE) : false

const preloadedState: AppState = storedState ? JSON.parse(storedState) : {
  versionPreferences: {}
}

export const store = configureStore<AppState, Actions>({
  reducer: appReducer,
  preloadedState
})

export type AppDispatch = typeof store.dispatch

