import { Popover, Transition } from '@headlessui/react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

export const Info = ({children, className, pointer = 'above'}:{
  children?: React.ReactNode
  className?: string
  pointer?: 'above' | 'below' | 'left' | 'right'
}) => {
  return <Popover className='relative'>
    <Popover.Button><InformationCircleIcon className='ml-2 w-5 overflow-visible' /></Popover.Button>
    <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
      <Popover.Panel className={classNames('absolute', className)}>
        <div className={classNames(
          "relative rounded-lg bg-black/80 text-white p-2 font-main font-light",
          "before:content-[''] before:absolute before:bg-black/80",
          pointerClasses(pointer)
        )}>
          {children}
        </div>
      </Popover.Panel>
    </Transition>
  </Popover>
}

function pointerClasses(pointer: 'above' | 'below' | 'left' | 'right') {
  switch (pointer) {
    case 'above':
      return 'before:mt-6 before:w-6 before:h-3 before:bottom-full before:left-[calc(50%_-_2.25rem_/_2)] before:[clip-path:polygon(12.5%_100%,_50%_0,_87.5%_100%)]'
    case 'below':
      return 'before:mb-6 before:w-6 before:h-3 before:top-full before:left-[calc(50%_-_2.25rem_/_2)] before:[clip-path:polygon(12.5%_0,_87.5%_0,_50%_100%)]'
    case 'left':
      return 'before:ml-6 before:w-3 before:h-6 before:right-full before:top-[calc(50%_-_2.25rem_/_2)] before:[clip-path:polygon(0_50%,_100%_12.5%,_100%_87.5%)]'
    case 'right':
      return 'before:mr-6 before:w-3 before:h-6 before:left-full before:top-[calc(50%_-_2.25rem_/_2)] before:[clip-path:polygon(0_12.5%,_100%_50%,_0_87.5%)]'
  }
}

// --bbColor: grey;
// --bbArrowSize: 1.5rem;
// --bbBorderRadius: 0.25rem;
// --bbPadding: 1rem;
// background: var(--bbColor);
// border-radius: var(--bbBorderRadius);
// padding: var(--bbPadding);
// position: relative;
// }

// [speech-bubble]::before{
//   content: ''; 
//   position: absolute;
//   background: var(--bbColor);
// }

// [speech-bubble][pbottom]{ margin-bottom: var(--bbArrowSize) }
// [speech-bubble][ptop]   { margin-top: var(--bbArrowSize); }
// [speech-bubble][pleft]  { margin-left: var(--bbArrowSize); }
// [speech-bubble][pright] { margin-right: var(--bbArrowSize); }