import classNames from "classnames"

export const ToggleButton = ({
  checked = false,
}:{
  checked?: boolean
}) => {


  return <div className={classNames(
    'w-9 h-5 outline-none ring-4 rounded-full peer-checked:bg-teal-600', 
    checked ? 'bg-teal-600' : 'bg-gray-600',
    'peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white',
    'after:content-[\'\'] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all',
  )}></div>
}