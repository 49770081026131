'use client'
import { useState } from "react";
import { Info } from "../../widget/info"
import { SearchInterface, useSearchUi } from "@/contexts/SearchUiProvider";
import { usePathname } from "next/navigation";
import { DrupalTaxonomyTermProduct } from "@/types";
import classNames from "classnames";
import { ToggleButton } from "@/components/widget/ToggleButton";

export const SearchProductFilterToggle = ({products}: {
  products?: DrupalTaxonomyTermProduct[]
}) => {

  const { runSearch, current } = useSearchUi()
  const [state, setState] = useState<boolean>()
  const pathname = usePathname()
  const currentState:boolean = (() => {
    if (!current) {
      return state ?? pathname == '/'
    }
    // Active search doesn't use product filter.
    if (!current.products) {
      return true
    }
    return false
  })()

  function toggleSwitch(newState: boolean) {
    const productFilter = products?.map(p => p.name);
    setState(newState);

    if (!current) {
      return;
    }

    // Product filter is applied
    if (!newState) {
      if (products?.length) {
        runSearch(current.searchPhrase, productFilter, 'toggleFilter')
        return;
      }
      const oldFilter = findLastProductFilter(current)
      if (oldFilter) {
        runSearch(current.searchPhrase, oldFilter, 'toggleFilter')
      }
      else {
        // This means there are no products to filter on.
        // This is a bug.
        console.error("Search toggle was set to filter on products but none implicitly found.");
      }
    }
    // This means have no filter.
    else {
      // Products already applied to current search
      if (!current?.products) {
        console.warn("Products already applied in current filter.");
        return
      }
      runSearch(current?.searchPhrase, undefined, 'toggleFilter')
    }
  }

  function findLastProductFilter(search?: SearchInterface) {
    if (!search) {
      return undefined
    }
    if (search?.products) {
      return search.products
    }
    if (search.searchPhrase === search.previous?.searchPhrase) {
      return findLastProductFilter(search.previous)
    }
    return undefined
  }

  if (pathname == '/' && current?.referer !== 'toggleFilter' && !findLastProductFilter(current)) {
    return <></>
  }

  return <label className={classNames("relative justify-self-end flex", 'cursor-pointer')}>
    <span className="block">
      <input 
        type="checkbox" 
        className="sr-only peer" 
        checked={currentState}
        onChange={() => toggleSwitch(!currentState)} 
      />
      <ToggleButton checked={currentState} />
    </span>
    
      <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 flex">
        Search all products
        <Info className="right-7 -top-10 w-56" pointer="right">
            Conduct search across all Acquia content
        </Info>
      </span>
  </label>
}